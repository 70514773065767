/* Default starter start */
*,
::after,
::before {
    box-sizing: border-box;
}

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&family=Montserrat&display=swap");

html {
    font-size: 100%;
}

/*16px*/

:root {
    /* colors */
    --primary-100: #d1fae5;
    --primary-200: #a7f3d0;
    --primary-300: #6ee7b7;
    --primary-400: #34d399;
    --primary-500: #10b981;
    --primary-600: #059669;
    --primary-700: #047857;
    --primary-800: #065f46;
    --primary-900: #064e3b;

    /* grey */
    --grey-50: #fafaf9;
    --grey-100: #f5f5f4;
    --grey-200: #e7e5e4;
    --grey-300: #d6d3d1;
    --grey-400: #a8a29e;
    --grey-500: #78716c;
    --grey-600: #57534e;
    --grey-700: #44403c;
    --grey-800: #292524;
    --grey-900: #1c1917;
    /* rest of the colors */
    --black: #222;
    --white: #fff;
    --red-light: #ef4444;
    --red-dark: #b91c1c;
    --green-light: #22c55e;
    --green-dark: #15803d;

    /* fonts  */
    --headingFont: "Roboto", sans-serif;
    --bodyFont: "Nunito", sans-serif;
    --small-text: 0.875rem;
    --extra-small-text: 0.7em;
    /* rest of the vars */
    --backgroundColor: var(--grey-400);
    --textColor: var(--grey-50);
    --borderRadius: 0.25rem;
    --letterSpacing: 1px;
    --transition: 0.3s ease-in-out all;
    --max-width: 1120px;
    --fixed-width: 600px;

    /* box shadow*/
    --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
    background: var(--backgroundColor);
    font-family: var(--bodyFont);
    font-weight: 400;
    line-height: 1.75;
    color: var(--textColor);
}

p {
    margin-bottom: 1.5rem;
    max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    margin-bottom: 1.38rem;
    font-family: var(--headingFont);
    font-weight: 400;
    line-height: 1.3;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
}

h1 {
    margin-top: 0;
    font-size: 3.052rem;
}

h2 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

small,
.text-small {
    font-size: var(--small-text);
}

a {
    text-decoration: none;
}

ul {
    list-style-type: none;
    padding: 0;
}

.img {
    width: 100%;
    display: block;
    object-fit: cover;
}

/* buttons */

.btn {
    cursor: pointer;
    color: var(--white);
    background: var(--primary-700);
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    box-shadow: var(--shadow-1);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
}

.btn:hover {
    background: var(--primary-700);
    box-shadow: var(--shadow-3);
}

.btn-hipster {
    color: var(--primary-500);
    background: var(--primary-200);
}

.btn-hipster:hover {
    color: var(--primary-200);
    background: var(--primary-700);
}

.btn-block {
    width: 100%;
}

/* alerts */
.alert {
    padding: 0.375rem 0.75rem;
    margin-bottom: 1rem;
    border-color: transparent;
    border-radius: var(--borderRadius);
}

.alert-danger {
    color: var(--red-dark);
    background: var(--red-light);
}

.alert-success {
    color: var(--green-dark);
    background: var(--green-light);
}

/* form */

/* .form {
    width: 90vw;
    max-width: var(--fixed-width);
    background: var(--white);
    border-radius: var(--borderRadius);
    box-shadow: var(--shadow-2);
    padding: 2rem 2.5rem;
    margin: 3rem auto;
} */

.form-label {
    display: block;
    font-size: var(--small-text);
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: var(--borderRadius);
    background: var(--backgroundColor);
    border: 1px solid var(--grey-200);
}

.form-row {
    margin-bottom: 1rem;
}

.form-textarea {
    height: 7rem;
}

::placeholder {
    font-family: inherit;
    color: var(--grey-400);
}

.form-alert {
    color: var(--red-dark);
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
}

/* alert */

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid var(--grey-400);
    border-radius: 50%;
    border-top-color: var(--primary-500);
    animation: spinner 0.6s linear infinite;
}

.loading {
    margin: 0 auto;
}

/* title */

.title {
    text-align: center;
}

.title-underline {
    background: var(--primary-500);
    width: 7rem;
    height: 0.25rem;
    margin: 0 auto;
    margin-top: -1rem;
}

/* underline */
.underline {
    text-decoration: underline;
}

/* Default starter end */

body * {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: large;
}

.form {
    margin: 0 0.5rem;
}

.prev {
    margin-right: 0.15rem;
}

.next {
    margin-left: 0.15rem;
}

.main-card {
    padding: 1rem;
    margin-top: 1rem;
    min-height: calc(100vh - (136.5px + 2rem));
}

.faIcon {
    width: 2.5rem;
    height: 2.5rem;
}

.p-faIcon {
    margin: 0.5rem auto;
}

.btn-show-answer {
    width: 8rem;
}

.show-answer {
    margin-top: 1rem;
}

.footnotes>h3 {
    font-size: 1rem;
}

.show-proof-text {
    font-size: 1rem;
}

.extra_text {
    font-size: 1rem;
}

.extra_text p,
.footnotes,
.footnote-ref {
    font-size: 0.75rem;
}

.footnote a {
    font-size: 0.6rem;
}

.audio,
.mp3link {
    font-size: 0.85rem;
}

.verse-num {
    font-size: 0.5rem;
    vertical-align: super;
}

.esv-logo {
    display: inline-block;
    font-size: 0.42rem;
    vertical-align: super;
}

.extra_text {
    display: inline-block;
}

span.navbar-brand {
    color: #fff !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center !important;
}

.copyright {
    color: var(--primary-700);
    text-decoration: underline;
}

/* * * Proof text * * */
.div-proof-text {
    padding-top: 1.25rem;
}

.show-proof-text h2 {
    display: inline-block;
    font-size: 1.2rem;
}

/* * * Modal * * */

#footer-col-translation {
    text-align: right;
    margin-right: 0.15rem;
}

#about-us {
    background: none;
    color: var(--primary-700);
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: inline-block;
    text-align: right;
    text-decoration: underline;
}

#esv-legal-stuff {
    background: none;
    color: var(--primary-700);
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: inline-block;
    text-align: right;
    text-decoration: underline;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    transition: all 0.3s linear;
    visibility: hidden;
    z-index: -1;
}

/* open and close modal */
.show-modal {
    visibility: visible;
    z-index: 10;
}

.modal-container {
    background-color: var(--primary-800);
    border-radius: 1%;
    width: 90vw;
    min-height: fit-content;
    max-width: 620px;
    text-align: center;
    display: grid;
    place-items: center;
    position: relative;
}

.modal-container h6 {
    line-height: 1.75rem !important;
}

@media screen and (max-width: 400px) {
    .modal-container {
        height: 45vh;
    }
}

.close-modal-btn {
    position: absolute;
    top: -0.6rem;
    right: 0.5rem;
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: rgb(225, 0, 0);
    /* cursor: pointer; */
}

.close-modal-btn:hover {
    color: rgb(180, 0, 0);
}

.modal-container span {
    display: block;
    padding-bottom: 0.25rem;
    font-size: 1.15rem;
    font-weight: 600;
}

.modal-container h6 {
    font-size: 1rem;
    text-align: left;
    padding: 1.75rem 0.5rem 1rem 1rem;
    line-height: 1.35rem;
}

.modal-container i {
    font-size: 1rem;
}